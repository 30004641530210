import { Button } from "@mui/material";
// import React from "react";
import { useState } from "react";
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './contact.scss'
import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';


const ContactForm = () => {

  const validationSchema = Yup.object().shape({
    name:Yup.string().required('Fullname is required'),
    email:Yup.string().required('Email is required').email('email is invalid').email('@ is required'),
    phone:Yup.number().required('Phone is required').min(10,'Enter 10 Digit number'),
    message:Yup.string().required('Message is required'),
  });

  const formik = useFormik({
    initialValues:{
      name:'',
      email:'',
      phone:'',
      message:'',
    },
    validationSchema,
    onSubmit:(data)=>{
      console.log(data);
    },
  });

  
  const handleSubmit = (e)=>{
    e.PreventDefault();
    axios.post('',{
      
    })
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_lqjolav', //email address service id, on which want to receive email
                     'template_mghyq6e', // your set template id 
                      form.current, 
                      'ULQAqd-YRtZQp90KQ') //your public key found in account section in EmailJS
      .then((result) => {
          console.log(result.text);
          e.target.reset();
          console.log("meaasge sent")
      }, (error) => {
          console.log(error.text);
      });
      formik.handleSubmit();
  };

  return (
    <div className="contact-form">
      <h1 className="title"><span>Send us a message</span></h1>
      <form ref={form} onSubmit={sendEmail}> 
      {/* abouv formik is not supported while we use formik.sendEmail */}
        <div className="input-group">
          <label htmlFor="">Name</label>
          <input
            type="text"
            name="name"
            className={'input-style' + (formik.errors?.name && formik.touched?.name
              ? ' is-invalid'
              : '')}
            onChange={formik.handleChange}
            value={formik.values?.name}
            placeholder="e.g. Jane Teressa"
          />
          <div className="invalid-feedback">
            {formik.errors.name && formik.touched.name
              ? formik.errors.name
              : null}
          </div>
        </div>

        <div className="input-group">
          <label htmlFor="">Phone</label>
          <input
            type="number"
            name="phone"
            className={'input-style-number' + (formik.errors?.phone && formik.touched?.phone
              ? ' is-invalid'
              : '')}
            placeholder="e.g. 85632533253"
            onChange={formik.handleChange}
            value={formik.values?.phone}
          />
           <div className="invalid-feedback">
            {formik.errors.phone && formik.touched.phone
              ? formik.errors.phone
              : null}
          </div>
        </div>

        <div className="input-group">
          <label htmlFor="">Email</label>
          <input
            type="text"
            name="email"
            className={'input-style' + (formik.errors?.email && formik.touched?.email ? ' is-invalid' : '') }
            onChange={formik.handleChange}
            value={formik.values?.email}
            placeholder="e.g. janeterressa@gmail.com" 
          />
          <div className="invalid-feedback">
            {formik.errors.email && formik.touched.email
              ? formik.errors.email
              : null}
          </div>
        </div>

        <div className="input-group">
          <label htmlFor="">Message</label>
          <textarea
            name="message"
            id=""
            cols="30"
            rows="4"
            className={'input-style' + (formik.errors?.message && formik.touched?.message
              ? ' is-invalid'
              : '')}
            placeholder="Hello...."
            onChange={formik.handleChange}
            value={formik.values?.message}
          ></textarea>
          <div className="invalid-feedback">
            {formik.errors.message && formik.touched.message
              ? formik.errors.message
              : null}
          </div>
        </div>
        <Button className="btn-send" type="submit">Send</Button>
      </form>
    </div>
  );
};

export default ContactForm;
