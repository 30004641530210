import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./service.scss";
import { s01, s02, s03, s04, s05, s06, s07,s08, line, circle } from "../../comman/images";
import Fade from "react-reveal/Fade";

const serviceData = [
  {
    title: "Blockchain:",
    image: s08,
    details: "With a solid understanding of the leading frameworks, we work to create unique blockchain solutions that spur productivity growth in your existing environment. We tailor our software to each client’s needs and seamlessly integrate it into their infrastructure, whatever complex it is."
  },
  {
    title: "Web Development:",
    image: s01,
    details: "Development gets deeper, it resolves more issues while giving you insights. Get a glimpse of our exceptional web development services. ",
  },
  {
    title: "Game Development:",
    image: s04,
    details: "Our endeavor is to translate your game vision into a world-class experiential reality, with precision and uncompromising quality. We provide end-to-end gaming services that take care of the entire game development lifecycle for you.",
  },
  {
    title: "Graphics Services:",
    image: s03, 
    details: "Want to create a unique brand impression in the world with an artful design experience? If Yes, then we make it possible in an artistic way. Make your brand outstanding with us. ",
  },
  {
    title: "Social Media Service:",
    image: s05,
    details: "We can design & implement a well-planned Social media strategy for your online business objectives. The Modular Journey - from Social media to Branding.",
  },
  {
    title: "SEO:",
    image: s06,
    details: "Utilize our shrewd, Google-friendly SEO strategies to be found online. Don’t just rank. Stay at the top of the search results, & customers’ minds, above the competition with our SEO services."
  },
  {
    title: "Content Writing:",
    image: s07,
    details: "Enhance your website's visibility. We create effective website content for businesses and individuals. Uplift your business & brand with a real, trusted & accurate content marketing service agency."
  }
  
];
const Service = () => {
  return (
    <div id="Services" className="service-style">
      <Container className="pt-0">
        <div className="head">
          {/* <p>Our Service Areas</p> */}
          <h1 className="serviceTitle">Transforming innovation with our powerful services developed for you</h1>
        </div>
        {serviceData?.map((el, index) => (
          <Row className="" key={index}>
            <Col lg="1" sm="1" xs="1" className="">
              <Fade bottom>
              <div className="flow-line">
                <img src={circle} className="circle" />
                <img
                  src={line}
                  className={`line ${index == 6 ? "last" : ""}`}
                />
              </div>
              </Fade> 
            </Col>
            <Col lg="11" sm="11" xs="11" className="">
                <Fade right>
              <div className="ser-list">
                <h1>{el.title}</h1>
                <div className="detail-sec">
                  <p>{el.details}</p>
                  <img src={el.image} className="img-sec" alt="" />
                </div>
              </div>
              </Fade>
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
};

export default Service;
