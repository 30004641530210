import React from "react"

import Service from "../service/Service"
import Clients from "./sections/clients/Clients"
import Clients2 from "./sections/clients/Clients2"
import Contact from "./sections/contact/Contact"
import HeroSec from "./sections/HeroSec/HeroSec"
import Projects from "./sections/projects/Projects"
import Whyus from "./sections/Whyus/Whyus"

const Home = () => {
  return (
    <div>
      <HeroSec />
      <Whyus />
      <Service />
      {/* <Projects /> */}
      {/* <Clients /> */}
      <Clients2 />
      <Contact />
    </div>
  )
}

export default Home
