import React from "react"
import "./clients.scss"
import Slider from "react-slick"
import {t1, t2, t3, t4, t5, t6, t7} from "../../../../comman/images"

const clientsData = [t1, t2, t3, t6, t4, t5, t7]
const Clients = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  }

  return (
    <div className="clients">
      <h1 className="title">Our Expertise!</h1>
      <Slider {...settings}>
        {clientsData?.map((el, index) => (
          <div key={index}>
            <img className="curve2" src={el} />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Clients
